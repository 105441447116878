.Attachment_form_main_Container {
  margin-top: 20px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.Attachment_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px dashed #9093a1;
  border-radius: 10px;
}
.Attachment_title {
  font-family: "inter";
  font-weight: 700;
  color: #003087;
  text-align: center;
  margin-bottom: 20px;
}
.error-visible {
  margin-bottom: 20px;
}

.Attachmentform_action {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.Attachment_sub_Container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.choose_file_field_attach_properties {
  font-size: 14px;
  color: gray;
  font-family: "Inter";
  height: 25px;
  min-width: 200px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5.5px 8px;
  border: 1px solid darkgrey;
  border-radius: 5px;
}
.css-1o3s0c4-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 7.5px 20px !important;
  font-size: 15px !important;
  height: 25px !important;
}
.customTextField {
  width: "190px";
}
