.Property_buttons {
    display: flex;
    margin-top: 52px;
    background-color: #F1F1F1;
    color: #000000;
    height: 81px;
    width: 31px;
    margin-left: "12px";
    border-radius: 3;
}
.Property_backbuttons{
    display: flex;
    margin-top: 54px;
    background-color: #F1F1F1;
    color: #000000;
    height: 80px;
    width: 31px;
}
.Property_image_flex{
    width:400px;
    height:400px;
    display:block;
}
.Property_image_mobile_flex{
  
    display:none;
}
.container_image{
    display:none;
}
.container_web{
    display:block;
}
.Property_image_flex_ipad{
    display: none;
    
   
  }
@media only screen and (max-width: 600px) {
    .Property_image_mobile_flex{
        display:block;
    }
    .Property_image_flex{
        display:none;

    
}
.container_image{
    display:block;
}
.container_web{
    display:none;
}
.Property_buttons {
    display: flex;
    margin-top: 19px;
    background-color: #F1F1F1;
    color: #000000;
    height: 48px;
    width: 25px;
    margin-left: "12px";
    border-radius: 3;
}
.Property_backbuttons{
    display: flex;
    margin-top: 19px;
    background-color: #F1F1F1;
    color: #000000;
    height: 45px;
    width: 25px;
    margin-right: "12px";
}
.Property_image_flex_ipad{
    display: none;
    
   
  }
}
@media only screen and (max-width: 850px) {
    .Property_image_flex_ipad{
      display: block;
      
     
    }
    .Property_image_mobile_flex{
        display:none;
    }
    .Property_image_flex{
        display:none;

    
}}
