.homePage_header_main_container {
  padding: 8px 12px;
  flex: 1;
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 4px 4px rgba(202, 202, 202, 0.25));
}
.collapse_menu {
  font-size: 30px !important;
  color: #003087;
}
.homePage_profile_notification_locale_container {
  display: flex;
  align-items: center;
}
.homePage_profile {
  display: flex;
  gap: 8px;
  /* margin-right: 90px; */
}
.homePage_userName {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 600;
}
.homePage_userRole {
  padding-top: 5px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
}
.notificationIcon {
  font-size: 33px !important;
}
.customBadge .MuiBadge-badge {
  top: 6px;
  right: 6px;
}
.header_profile_dropdown {
  display: none;
}
.homePage_profile_notification {
  display: flex;
  gap: 10;
}
.notification_header{
  margin-right: 130px;
}
@media only screen and (max-width: 700px) {
  .homePage_profile_notification_locale_container {
    display: none;
  }
  .header_profile_dropdown {
    display: flex;
    align-items: flex-end;
    margin-right: 135px;
  }
  .homePage_profile {
    display: none;
  }
}
@media only screen and (max-width: 550px) {
  .header_profile_dropdown {
    display: flex;
    align-items: flex-end;
    margin-right: 97px;
  }
}