.notification_main_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
}
.notification_email_dateTime {
  align-items: center;
  gap: 5px;
}
.notification_email {
  font-size: 12px;
  font-weight: bold;
}
.notification_dateTime {
  font-size: 10px;
  color: gray;
  white-space: nowrap;
  padding: 15px;
}
.notification_message {
  margin-top: 10px;
  font-size: 14px;
}
