.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.agent_form_edit_details {
  display: flex;
  gap: 5px;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.agent_form_header {
  display: flex;
  justify-content: space-between;
}
.agent_form_top_action {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
}
.agent_form_back {
  color: #003087;
  border: 1px solid #003087;
  cursor: pointer;
  font-family: "inter";
  font-size: 15px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 5px 8px;
  width: 70px;
  border-radius: 7.5px;
}
.agent_form_main_Container {
  margin-top: 30px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.agent_formContainer {
  margin: 20px;
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
}
.agent_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.agent_form_row {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
  margin-bottom: 15px;
}
.agent_form_label {
  color: #10002e;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 2px;
}
.agent_form_row_label_field {
  display: flex;
  flex-direction: column;
}
.agent_form_action_button {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}
.css-1o3s0c4-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 6.5px 14px !important;
  font-size: 15px !important;
  height: 25px !important;
  font-family: "Inter";
}

@media only screen and (max-width: 600px) {
  .agent_form_row {
    grid-template-columns: auto;
    margin-bottom: 0px;
  }
}
