.fieldValidation {
  color: red;
  margin-top: 5px;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.property_Form {
  width: 500px;
}
.property_form_label {
  font-size: 14px;
  margin: 8px 0px;
  font-weight: 500;
  font-family: "Inter";
}
.property_type_form_action {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}
.property_type_form_input
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 8px !important;
  font-size: 13px !important;
  font-family: "Inter";
}
.property_type_form_input
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px 8px !important;
  font-size: 13px !important;
  font-family: "Inter";
}
@media only screen and (max-width: 615px) {
  .property_Form {
    width: 300px;
  }
}
@media only screen and (max-width: 415px) {
  .property_Form {
    width: 200px;
  }
}
